@import '../../assets/styles/variables';

.proof_page_wrapper{
    width: 100%;
    max-width: 100vw;
    height: auto;
    padding: 29px 0 0;
    .proof_page_content{
        width: 88%;
        margin: 0 auto;
        position: relative;
        h2{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            font-size: clamp(20px, 2.22vw, 32px);
            line-height: 125%;
            letter-spacing: 0.1px;
            text-align: left;
            color: #3C81B9;
            max-width: 100%;
        }
        h4{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            font-size: clamp(14px, 1.4vw, 24px);
            line-height: 125%;
            letter-spacing: 0.1px;
            text-align: center;
            color: #B1B1B1;
            max-width: 100%;
            margin-top: 8px;
        }
        .proof_page_text{
            padding: 0;
           
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: clamp(14px, 1.11vw, 16px);
                line-height: 150%;
                text-align: justify;
                letter-spacing: 0.3px;
                color: #373F41;
                max-width: 100%;
                margin-top: 18px;
            }
            .list_link {
                margin-top: 18px;
                a{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: clamp(14px, 1.11vw, 16px);
                    line-height: 112%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.3px;
                    text-decoration-line: underline;
                }
            }
        }

        .proof_page_img{
            padding: 0;
            padding-left: 9%;
           img{
            width: 100%;
           }
        }

        .proof_page_tabs{
            margin-top: 87px;
            width: 100%;
            h2{
                text-align: center;
            }
            .proof_tabs{
                margin-top: 41px;
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .proof_page_wrapper {
        padding-top: 30px !important;
        .proof_page_content{
            width: 95%;
            .proof_page_text{
                margin-bottom: 30px;
            }
            .proof_page_img{
                padding-left: 0;
            }
            .proof_page_tabs{
                margin-top: 30px;
            }
        }
    }
}