@import '../../assets/styles/variables';
body{
    background: #ffffff;
}
.readmore_page_wrapper{
    width: 100%;
    max-width: 100vw;
    height: auto;
    padding: 56px;
    .readmore_page_content{
        text-align: center;
        width: 100%;
        height: 100%;
        position: relative;

        .site_logo{
            display: inline-block;
            text-align: right;
            h1, h5{
                color: $textGrey;
                span{
                    color: $primaryColor;
                }
            }
            h1{
                font-family: 'Tahoma', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 23px;
                line-height:  78%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
            }
            h5{
                font-family: 'Mulish', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 129%;
                letter-spacing: 0.2px;
                color: $textGrey;
            }
        }

         
    }
}

@media screen and (max-width: 768px) {
    .readmore_page_wrapper{
        padding: 56px 30px;
    }
}
@media screen and (max-width: 500px) {
    .readmore_page_wrapper{
        padding: 56px 15px;
    }
}