@import '../../assets/styles/variables';
// $textBoxHeight: 
.footer{
    background: $primaryColor;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    padding-bottom: 51px;
    
    .footer_text_box{
        position: relative;
        width: 570px;
        height: auto;
        min-height: 95.49px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 44px;
        transform: translateY(-50%);
        
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: clamp(14px, 1.11vw, 16px);
            line-height: 144% ;
            text-align: center;
            letter-spacing: 0.2px;
            color: $primaryColor;
        }
    }
    .footer_copytights{
        p{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 133%;
            letter-spacing: 0.2px;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

@media screen and (max-width: 768px) {
    .footer .footer_text_box{
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .footer .footer_text_box{
        width: 90%;
        padding: 10px 15px;
    }
}