@import '../../assets/styles/variables';

.message_page_wrapper{
    width: 100%;
    max-width: 100vw;
    height: auto;
    padding: 29px 0 0;
    h2{
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 800;
        font-size: clamp(20px, 2.22vw, 32px);
        line-height: 125%;
        letter-spacing: 0.1px;
        text-align: center;
        color: #3C81B9;
        max-width: 100%;
    }
    .page_title{
        h2{
            margin: 0 0 16px;
        }
        h5{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: clamp(15px, 1.25vw, 18px);
            line-height: 133% ;
            text-align: center;
            letter-spacing: 0.3px;
            color: #373F41;
            max-width: 770px;
            margin: 0 auto;
        }
    }
    .message_page_content{
        width: 87.4%;
        margin: 0 auto;
        margin-top: 21px;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.28);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 31px 45px 29px 50px;
        p, li{            
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: clamp(14px, 1.11vw, 32px);
            line-height: 150%;
            text-align: justify;
            letter-spacing: 0.3px;
            color: #373F41;
        }
        ul{
            margin-top: 24px;
        }
        p.author_name{
            margin-top: 24px;
            color: #000000;
            font-family: 'Poppins';
        }
        .btn.btn-primary{
            padding: 16px 21px;
            border-radius: 0;
            background: #3C81B9;
            transform: rotate(-0.1deg);
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: clamp(12px, 0.99vw, 14px);
            line-height: 129%;
            display: inline-flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.3px;
            margin-top: 34px;
            color: #FFFFFF;
        }
        .wiki_link{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: clamp(12px, 1.11vw, 16px);
            line-height: 112%;
            display: inline-flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.3px;
            text-decoration-line: underline;
            color: #707070;
            margin-top: 34px;
        }

    }
}

@media screen and (max-width: 500px) {
    .message_page_wrapper {
        padding-top: 30px !important;
        .message_page_content{
            padding: 20px;
            .btn.btn-primary{
                padding: 14px 9px;
            }
        }
    }
}