.proof_tabs_wrapper{
    .proofTabsCon{
        background: #E2E0E0;
        border-radius: 3px;
        overflow: hidden;
        .MuiTabs-flexContainer{
            justify-content: flex-start;
            max-width: 100%;
        }
        .pageTab{
            color: #373F41;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.3px;
            padding: 0 12px;
            border-right: 1px solid rgba(112, 112, 112, 0.31);
            min-width: 70px;
            min-height: 79px;
            &:last-child{
                flex: 2;
                border-right: 0;
            }
            &.Mui-selected{
                background: #5AA4E0;
                color: #ffffff;
            }
        }
    }
    .MuiBox-root{
        padding: 0;
    }

    .proof_tab_content{
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.28);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        padding: 35px 43px 42px 22px;
        margin-top: 29px;

        h3{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            font-size: clamp(16px, 1.25vw, 18px);
            line-height:  100% ;
            text-align: center;
            letter-spacing: 0.2px;
            color: #000000;
            margin-bottom: 31px;
        }
        p, .list_item{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: clamp(14px, 1.11vw, 16px);
            line-height:  150%;
            text-align: justify;
            letter-spacing: 0.3px;
            color: #373F41;
        }

        .miracles_list{
            .list_item{
                text-align: left;
                margin-bottom: 18px;
            }
        }
        .MuiTable-root{
            background: #FFFFFF;
            margin: 0 auto;
        }
        .MuiTableCell-root.MuiTableCell-head{
            padding: 11px 6px 6px 16px;

        }
        .MuiTableCell-root {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: clamp(12px, 0.99vw, 14px);
            line-height:  171%;
            letter-spacing: 0.3px;
            padding: 3px 0;
            &:nth-of-type(1){
                padding: 3px 0 3px 23px;
            }
        }
        .table_container{
            width: 280px;
            border: 1px solid rgba(0, 0, 0, 0.35);
            display: inline-block;
            margin: 31px auto 0;
            border-radius: 12px;
            &.hm_table{
                width: 434px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .proof_tabs_wrapper {
        .proofTabsCon .MuiTabs-flexContainer{
            overflow: hidden;
            overflow-x: scroll;
            &::-webkit-scrollbar{
                display: none;
            }
        }
        .proof_tab_content{
            width: 100%;
            padding: 15px !important;

            .table_container.hm_table{
                width: auto;
                max-width: 100%;
            }
        }
    }
}