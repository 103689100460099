.contact_page_wrapper{
    .page_title{
        padding: 41px;
        border: 1px solid #3C81B9;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 16px;
        max-width: 752px;
        margin: 30px auto 0;
        background-color: #ffffff;

        h3{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            font-size: clamp(20px, 2.22vw, 32px);
            line-height:  125%;
            text-align: center;
            letter-spacing: 0.1px;
            color: #3C81B9;
        }
        p{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: clamp(14px, 1.11vw, 16px);
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.3px;
            color: #3C81B9;
        }
    }
    .page_content{
        display: flex;
        gap: 36px;
        margin-top: 99px;
        
        .contact_item{
            width: calc(33% - 36px);
            height: 184px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.15);
            border-radius: 19px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .item_icon{
                margin-bottom: 12px;
            }
            h4{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 700;
                font-size: clamp(15px, 1.25vw, 18px);
                line-height:  133%;
                letter-spacing: 0.1px;
                color: #171717;
                margin-bottom: 6px;
            }
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: clamp(14px, 1.11vw, 16px);
                line-height: 150%;
                text-align: center;
                letter-spacing: 0.3px;
                color: #373F41;
            }

            .social_links{
                display: flex;
                align-items: center;
                gap: 14px;
                .social_icon{
                    width: 36px;
                    img, svg{
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .contact_page_wrapper .page_content{
        flex-wrap: wrap;
        .contact_item{
            width: 100%;
            padding: 15px;
        }
    }
}
@media screen and (max-width: 500px) {
    .contact_page_wrapper .page_title{
        padding: 15px !important;
    }
}