@import '../../assets/styles/variables';

.basicfacts_page_wrapper{
    width: 100%;
    max-width: 100vw;
    height: auto;
    padding: 29px 0 0;
    .basicfacts_page_content{
        width: 88%;
        margin: 0 auto;
        position: relative;
        h2{
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            font-size: clamp(18px,2.22vw,32px);
            line-height: 125%;
            letter-spacing: 0.1px;
            text-align: left;
            color: #3C81B9;
            max-width: 100%;
        }
        .basicfacts_page_text{
            padding: 0;
           
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: clamp(14px,1.11vw,16px);
                line-height: 150%;
                text-align: justify;
                letter-spacing: 0.3px;
                color: #373F41;
                max-width: 100%;
                margin-top: 18px;
            }
        }

        .basicfacts_page_img{
            padding: 0;
            padding-left: 9%;
           img{
            width: 100%;
           }
        }

        .basicfacts_cards_section{
            margin-top: 48px;

            .basic_facts_cards_wrapper{
                margin: 0 auto;
                margin-top: 45px;
                max-width: 872px;
                overflow: hidden;
            }
            .grid_scroller{
                width: 278px;
                height: 10px;
                margin: 79px auto 0;
                background: #D8D8D8;
                border-radius: 10px;
                position: relative;
                display: flex;
                align-items: center;

                .scroller_thumb{
                    width: 50%;
                    height: 10px;
                    position: relative;
                    border-radius: 10px;
                    cursor: pointer;
                    &.active{
                        background: #3C81B9;
                    }
                }
            }
        }
        .superhuman_section{
            margin-top: 39px;
            .section_title{
                p{
                    max-width: 726px;
                    margin: 8px auto 0;
                }
            }
            .smd_card{
                margin-top: 64px;
                .card_content{
                    background: #FFFFFF;
                    border: 1px solid #C3CBCD;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    padding: 17px 13px;
                    .card_title{
                        font-family: 'Mulish';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 133%;
                        letter-spacing: 0.1px;
                        color: #171717;
                        margin: 0 0 7px;
                    }
                    .card_text{
                        margin: 0;
                        letter-spacing: 0.3px;
                        color: #373F41;
                    }
                }
            }
            
            .readmore_link{
                margin-top: 38px;
            }
        }

    }
}

@media screen and (max-width: 500px) {
    .basicfacts_page_wrapper .basicfacts_page_content{
        padding-top: 40px;
        .basicfacts_page_text{
            padding: 20px 0;
        }
        .basicfacts_page_img{
            padding-left: 0;
        }
        .superhuman_section .smd_card{
            margin-top: 0;
            padding-top: 30px !important;
            &:nth-of-type(1){
                margin-top: 45px;
            }
        }
    }
}