@import '../../assets/styles/variables';

.intro_page_wrapper{
    width: 100%;
    max-width: 100vw;
    height: auto;
    padding: 29px 0 0;
    .intro_page_content{
        width: 100%;
        position: relative;

        .intro_page_text{
            h2{
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 800;
                font-size: clamp(18px, 2.22vw,32px);
                line-height: 125%;
                letter-spacing: 0.1px;
                text-align: left;
                color: #3C81B9;
                max-width: 92%;
            }
            p{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: clamp(14px,1.11vw,16px);
                line-height: 150%;
                text-align: justify;
                letter-spacing: 0.3px;
                color: #373F41;
                max-width: 92%;
                margin-top: 18px;
            }
        }

        .intro_page_img{
            .intro_page_slider_con{
                margin-top: 34px;
                h5{
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 800;
                    font-size: clamp(15px, 1.25vw,18px);
                    line-height: 100%;
                    letter-spacing: 0.2px;
                    text-align: left;
                    color: $primaryColor;
                }

                .intro_page_slider{
                    margin-top: 17px;
                    padding: 0;
                }
            }
        }

    }
}

@media screen and (max-width: 768px){
    .intro_page_wrapper .intro_page_content .intro_page_item{
        padding-left: 0;
    }
}
@media screen and (max-width: 500px) {
    .intro_page_wrapper .intro_page_content{
        flex-wrap: wrap;
        .intro_page_text {
            padding: 30px 20px;
            h2, p{
                max-width: 100%;
            }
        }
        .intro_page_img{
            padding: 30px 20px;
        }
    }
}