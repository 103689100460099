$tabGap: 4.65vw;
.tabs_page_wrapper{
    width: 100%;
    .tabs_wrapper{
        width: 100%;
        margin-top: 30px;
        .pageTabsCon{
            width: 100%;
            position: relative;
            .pageTab{
                margin-bottom: 8px;
                padding: 16px 29px;
                border: 1px solid #3C81B9;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                border-radius: 9px;
                font-family: 'Mulish';
                font-style: normal;
                font-weight: 600;
                font-size:clamp(10px,1.11vw,16px);
                line-height: 112%;
                display: flex;
                align-items: center;
                letter-spacing: 0.2px;
                color: #707070;
                margin-left: $tabGap/2;
                margin-right: $tabGap/2;
                position: relative;
                overflow: visible;
                z-index: 1;
                &::after{
                    content: '';
                    width: $tabGap;
                    height: 2px;
                    position: absolute;
                    background: #707070;
                    z-index: -1;
                    left: 100%;
                }
                &:last-child::after{
                    display: none;
                }
                &.active,
                &.Mui-selected{
                    background: #3C81B9;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    color: #ffffff;
                    &::after{
                        background: #3C81B9;
                    }
                }
            }
        }

    }
    .link_container{
        width: 88%;
        margin: 38px auto 0;
        a{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: clamp(14px, 1.11vw, 16px);
            line-height:  112%;
            letter-spacing: 0.3px;
            text-decoration-line: underline;
            color: #3C81B9;
        }

        @media screen and (max-width: 468px) {
            gap: 20px !important;
            a{
                width: 100%;
            }
        }
    }
}
.divider{
    display: inline-block;
    width: 2px;
    height: 15px;
    background: #3C81B9;
    border-radius: 6px;
    @media screen and (max-width: 468px) {
        display: none;
    }
}
@media screen and (min-width: 1440px) {
    $tabGap: 67px;
}
@media screen and (max-width: 992px) {
    $tabGap: 3vw;
    .tabs_page_wrapper .tabs_wrapper .pageTabsCon{
        min-height: 40px;
        .pageTab{
            padding: 10px 20px;
            min-height: 40px;
            margin-left: $tabGap/2;
            margin-right: $tabGap/2;
            &::after{
                width: $tabGap;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .tabs_page_wrapper .tabs_wrapper .pageTabsCon .pageTab{
        padding: 5px 10px;
        font-size: 8px;
        min-height: 32px;
    }
}
@media screen and (max-width: 600px) {
    .tabs_page_wrapper .tabs_wrapper .pageTabsCon {
        max-width: 100%;
        // overflow-x: scroll;
        .MuiTabs-flexContainer{
            justify-content: flex-start;
            overflow-x: scroll;
            padding-bottom: 10px;
            // &::-webkit-scrollbar{
            //     display: none;
            // }
        }
        .pageTab{
            padding: 5px 10px;
            font-size: 8px;
            min-height: 32px;
        }
    }
}
@media screen and (max-width: 500px) {
    .tab_container .MuiBox-root:nth-of-type(1){
        padding: 0;
    }
}