@import '../../assets/styles/variables';
.carousel-root {
    width: 100%;
    .carousel .slide {
      width: 100%;
      height: auto; 
    }

    .carousel {

        &.carousel-slider{
            padding-bottom: 50px;
        }
        .control-dots{
            bottom: 0;
            outline: 0;
    
            .dot{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #CDCDCD;
                opacity: 1;
                margin: 0 2px;
                box-shadow: none;
                &.selected{
                    background: $primaryColor;
                }
            }
        }

        .slider-wrapper{
            padding: 0;
            .slide{
                width: 100%;
                padding: 0 17px 17px;
                .carousel_item{
                    width: 100%;
                    height: 123px; 
                    padding: 14px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    h6{                   
                        font-family: 'Mulish';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 112%;
                        letter-spacing: 0.2px;
                        color: #000000;
                    }
                }
            }
        }
    }
}

  
  