@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Tahoma';
    src: url('../fonts/Tahoma-Bold.eot');
    src: url('../fonts/Tahoma-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tahoma-Bold.woff2') format('woff2'),
        url('../fonts/Tahoma-Bold.woff') format('woff'),
        url('../fonts/Tahoma-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma';
    src: url('../fonts/Tahoma.eot');
    src: url('../fonts/Tahoma.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tahoma.woff2') format('woff2'),
        url('../fonts/Tahoma.woff') format('woff'),
        url('../fonts/Tahoma.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma';
    src: url('../fonts/Tahoma_1.eot');
    src: url('../fonts/Tahoma_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tahoma_1.woff2') format('woff2'),
        url('../fonts/Tahoma_1.woff') format('woff'),
        url('../fonts/Tahoma_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma';
    src: url('../fonts/Tahoma-Bold_1.eot');
    src: url('../fonts/Tahoma-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Tahoma-Bold_1.woff2') format('woff2'),
        url('../fonts/Tahoma-Bold_1.woff') format('woff'),
        url('../fonts/Tahoma-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

