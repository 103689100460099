@import '../../assets/styles/variables';

.home_wrapper{
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url(../../assets/images/home-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 56px;
    .home_content{
        text-align: center;
        width: 100%;
        height: 100%;
        position: relative;

        .site_logo{
            display: inline-block;
            text-align: right;
            h1, h5{
                color: $textGrey;
                span{
                    color: $primaryColor;
                }
            }
            h1{
                font-family: 'Tahoma', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 23px;
                line-height:  78%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
            }
            h5{
                font-family: 'Mulish', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 129%;
                letter-spacing: 0.2px;
            }
        }

        .home_banner_text{
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .banner_text{
                display: inline-block;

                h4{
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 700;
                    font-size: clamp(14px,1.67vw,24px);
                    line-height: 1;
                    letter-spacing: 0.2px;
                    text-align: left;
                    text-transform: uppercase;
                    color: $textLight;
                }
                h2{
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 700;
                    font-size: clamp(30px,6.7vw,96px);
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.2px;
                    text-align: center;
                    color: #FFFFFF;
                    span{
                        color: $primaryColor;
                    }
                }
                .next_page_link{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: clamp(13px,1.3vw,20px);
                    line-height:  1;
                    display: block;
                    text-align: right;
                    letter-spacing: 0.3px;
                    text-decoration-line: underline;
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media screen and (max-width: 500px){
    .home_wrapper{
        padding: 56px 30px;
        .home_content .home_banner_text .banner_text .next_page_link span{
            display: inline-block;
            width: 8px;
            svg{
                width: 100%;
            }
        }
    }
}