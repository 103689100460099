@import '../../assets/styles/variables';
body{
    background: #0A0E17;
}
.start_wrapper{
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url(../../assets/images/start-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 56px;
    .start_content{
        text-align: center;
        width: 100%;
        height: 100%;
        position: relative;

        .site_logo{
            display: inline-block;
            text-align: right;
            h1, h5{
                color: $textGrey;
                span{
                    color: $primaryColor;
                }
            }
            h1{
                font-family: 'Tahoma', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 23px;
                line-height:  78%;
                letter-spacing: 0.2px;
                text-transform: uppercase;
            }
            h5{
                font-family: 'Mulish', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 129%;
                letter-spacing: 0.2px;
                color: $textGrey;
            }
        }

        .start_banner_text{
            position: absolute;
            width: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .banner_text{
                display: inline-block;
                max-width: 1110px;
                h3{
                    font-family: 'Mulish';
                    font-style: normal;
                    font-weight: 700;
                    font-size: clamp(32px,3.5vw,48px);
                    line-height: 1;
                    letter-spacing: 0.2px;
                    color: #FFFFFF;
                    margin: 0;
                    span{color: $primaryColor;}
                }
                h6{
                    margin: 16px 27px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: clamp(14px,1.11vw,16px);
                    line-height: 175%;
                    text-align: center;
                    letter-spacing: 0.2px;
                    color: #FFFFFF;
                }

                .continue_btn{
                    height: 42px;
                    width: 305px;
                    background: #3C81B9;
                    border-radius: 4px;
                    text-transform: none;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: clamp(11px,1vw,14px);
                    line-height:  129%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.3px;
                    color: #FFFFFF;
                    
                }
            }
        }
    }
}

@media screen and (max-width:500px) {
    .start_wrapper{
        padding: 50px 30px;
        .start_content .start_banner_text .banner_text h6{
            margin: 16px 0;
        }
    }
}